/* @charset "utf-8"; */
/* CSS Document */

// SAKINA - START

/* THEME - START */

$THEME_PRIMARY_DARK_COLOR: #004E94
$THEME_PRIMARY_DARK_RED: 0
$THEME_PRIMARY_DARK_GREEN: 78
$THEME_PRIMARY_DARK_BLUE: 148
$THEME_PRIMARY_NORMAL_COLOR: #005DA3
$THEME_PRIMARY_NORMAL_RED: 0
$THEME_PRIMARY_NORMAL_GREEN: 93
$THEME_PRIMARY_NORMAL_BLUE: 163
$THEME_PRIMARY_LIGHT_COLOR: #1476BC
$THEME_PRIMARY_LIGHT_RED: 20
$THEME_PRIMARY_LIGHT_GREEN: 118
$THEME_PRIMARY_LIGHT_BLUE: 188
$THEME_PRIMARY_SOFT_COLOR: #2876BC
$THEME_PRIMARY_SOFT_RED: 40
$THEME_PRIMARY_SOFT_GREEN: 118
$THEME_PRIMARY_SOFT_BLUE: 188
$THEME_PRIMARY_ACCENT_COLOR: #29ABE2
$THEME_PRIMARY_ACCENT_RED: 41
$THEME_PRIMARY_ACCENT_GREEN: 171
$THEME_PRIMARY_ACCENT_BLUE: 226
$THEME_PRIMARY_HIGHLIGHT_COLOR: #6BB9FF
$THEME_PRIMARY_HIGHLIGHT_RED: 107
$THEME_PRIMARY_HIGHLIGHT_GREEN: 185
$THEME_PRIMARY_HIGHLIGHT_BLUE: 255

$THEME_SECONDARY_DARK_COLOR: #F26F21
$THEME_SECONDARY_DARK_RED: 242
$THEME_SECONDARY_DARK_GREEN: 111
$THEME_SECONDARY_DARK_BLUE: 33
$THEME_SECONDARY_NORMAL_COLOR: #F7931E
$THEME_SECONDARY_NORMAL_RED: 247
$THEME_SECONDARY_NORMAL_GREEN: 147
$THEME_SECONDARY_NORMAL_BLUE: 30

$THEME_TERTIARY_DARK_COLOR: #787878
$THEME_TERTIARY_DARK_RED: 120
$THEME_TERTIARY_DARK_GREEN: 120
$THEME_TERTIARY_DARK_BLUE: 120
$THEME_TERTIARY_NORMAL_COLOR: #B4B4B4
$THEME_TERTIARY_NORMAL_RED: 180
$THEME_TERTIARY_NORMAL_GREEN: 180
$THEME_TERTIARY_NORMAL_BLUE: 180
$THEME_TERTIARY_LIGHT_COLOR: #E6E6E6
$THEME_TERTIARY_LIGHT_RED: 230
$THEME_TERTIARY_LIGHT_GREEN: 230
$THEME_TERTIARY_LIGHT_BLUE: 230
$THEME_TERTIARY_SOFT_COLOR: #FFFFFF
$THEME_TERTIARY_SOFT_RED: 255
$THEME_TERTIARY_SOFT_GREEN: 255
$THEME_TERTIARY_SOFT_BLUE: 255
$THEME_FORM_LABEL_ORANGE_COLOR: #F27024

/* THEME - END */

/* INDICATOR - START */

$THEME_INDICATOR_SUCCESS_DARK_COLOR: #009245
$THEME_INDICATOR_SUCCESS_DARK_RED: 0
$THEME_INDICATOR_SUCCESS_DARK_GREEN: 146
$THEME_INDICATOR_SUCCESS_DARK_BLUE: 69
$THEME_INDICATOR_SUCCESS_NORMAL_COLOR: #14A659
$THEME_INDICATOR_SUCCESS_NORMAL_RED: 20
$THEME_INDICATOR_SUCCESS_NORMAL_GREEN: 166
$THEME_INDICATOR_SUCCESS_NORMAL_BLUE: 89
$THEME_INDICATOR_WARNING_COLOR: #F15A24
$THEME_INDICATOR_WARNING_RED: 241
$THEME_INDICATOR_WARNING_GREEN: 90
$THEME_INDICATOR_WARNING_BLUE: 36
$THEME_INDICATOR_FAIL_DARK_COLOR: #C1272D
$THEME_INDICATOR_FAIL_DARK_RED: 193
$THEME_INDICATOR_FAIL_DARK_GREEN: 36
$THEME_INDICATOR_FAIL_DARK_BLUE: 45
$THEME_INDICATOR_FAIL_NORMAL_COLOR: #D53841
$THEME_INDICATOR_FAIL_NORMAL_RED: 213
$THEME_INDICATOR_FAIL_NORMAL_GREEN: 56
$THEME_INDICATOR_FAIL_NORMAL_BLUE: 65
$THEME_INDICATOR_INFORMATION_COLOR: #0071BC
$THEME_INDICATOR_INFORMATION_RED: 0
$THEME_INDICATOR_INFORMATION_GREEN: 113
$THEME_INDICATOR_INFORMATION_BLUE: 188
$THEME_INDICATOR_SEVERITY_URGENT_COLOR: #FF0000
$THEME_INDICATOR_SEVERITY_URGENT_RED: 255
$THEME_INDICATOR_SEVERITY_URGENT_GREEN: 0
$THEME_INDICATOR_SEVERITY_URGENT_BLUE: 0
$THEME_INDICATOR_SEVERITY_HIGH_COLOR: #C92D00
$THEME_INDICATOR_SEVERITY_HIGH_RED: 201
$THEME_INDICATOR_SEVERITY_HIGH_GREEN: 45
$THEME_INDICATOR_SEVERITY_HIGH_BLUE: 0
$THEME_INDICATOR_SEVERITY_MEDIUM_COLOR: #F7941D
$THEME_INDICATOR_SEVERITY_MEDIUM_RED: 247
$THEME_INDICATOR_SEVERITY_MEDIUM_GREEN: 148
$THEME_INDICATOR_SEVERITY_MEDIUM_BLUE: 29
$THEME_INDICATOR_SEVERITY_LOW_COLOR: #D8CB30
$THEME_INDICATOR_SEVERITY_LOW_RED: 216
$THEME_INDICATOR_SEVERITY_LOW_GREEN: 203
$THEME_INDICATOR_SEVERITY_LOW_BLUE: 48

/* INDICATOR - END */

/* LIST - START */

$THEME_LIST_EVEN_COLOR: #FFFFFF
$THEME_LIST_EVEN_RED: 255
$THEME_LIST_EVEN_GREEN: 255
$THEME_LIST_EVEN_BLUE: 255
$THEME_LIST_EVEN_OPACITY: 0.2
$THEME_LIST_ODD_COLOR: #FFFFFF
$THEME_LIST_ODD_RED: 255
$THEME_LIST_ODD_GREEN: 255
$THEME_LIST_ODD_BLUE: 255
$THEME_LIST_ODD_OPACITY: 0.1

/* LIST - END */

/* CONVERSATION - START */

$THEME_CONVERSATION_CUSTOMER_COLOR: #E0BB16
$THEME_CONVERSATION_CUSTOMER_RED: 224
$THEME_CONVERSATION_CUSTOMER_GREEN: 187
$THEME_CONVERSATION_CUSTOMER_BLUE: 22
$THEME_CONVERSATION_DIVISION_COLOR: #943707
$THEME_CONVERSATION_DIVISION_RED: 148
$THEME_CONVERSATION_DIVISION_GREEN: 55
$THEME_CONVERSATION_DIVISION_BLUE: 7
$THEME_CONVERSATION_PRIVATE_COLOR: #F7941D
$THEME_CONVERSATION_PRIVATE_RED: 247
$THEME_CONVERSATION_PRIVATE_GREEN: 148
$THEME_CONVERSATION_PRIVATE_BLUE: 29
$THEME_CONVERSATION_PUBLIC_COLOR: #1C75BC
$THEME_CONVERSATION_PUBLIC_RED: 28
$THEME_CONVERSATION_PUBLIC_GREEN: 117
$THEME_CONVERSATION_PUBLIC_BLUE: 188
$THEME_CONVERSATION_DISABLED: #6D6E71
$THEME_CONVERSATION_DISABLED_RED: 109
$THEME_CONVERSATION_DISABLED_GREEN: 110
$THEME_CONVERSATION_DISABLED_BLUE: 113

/* CONVERSATION - END */

/* SCORE - START */

$THEME_SCORE_VERYLOW: #00AF4F
$THEME_SCORE_LOW: #92D14D
$THEME_SCORE_MEDIUM: #FFB500
$THEME_SCORE_HIGH: #ED7D31
$THEME_SCORE_VERYHIGH: #F50303

/* SCORE - END */

/* STATUS - START */

$THEME_STATUS_APPROVED: #20B476
$THEME_STATUS_WAITINGFORAPPROVALCHECKER: #F29202
$THEME_STATUS_WAITINGFORAPPROVALSIGNER: #F2B602
$THEME_STATUS_REJECTED: #D01A21
$THEME_STATUS_REVISE: #4386B7

/* STATUS - END */

/* RRNEWAL - START */

$THEME_POLICY_EXECUTIVE_COLOR: #d4af37
$THEME_POLICY_EXECUTIVE_RED: 212
$THEME_POLICY_EXECUTIVE_GREEN: 175
$THEME_POLICY_EXECUTIVE_BLUE: 55

/* RENEWAL - START */

// SAKINA - END

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SETUP - START

/* SEMANTIC ELEMENTS - START */

div.DivBackground
	+generateBackgroundGradientTopBottom($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)

footer
	background-image: url($URL_SHAPE_RESOURCE + "shape_footer.svg")
	background-color: transparent
	background-position: top
	background-repeat: no-repeat
	background-size: cover

+generateInputPlaceholder($THEME_TERTIARY_NORMAL_COLOR)

/* SEMANTIC ELEMENTS - END */

/* NEUTRALIZATION - START */

h1, h2, h3, h4, h5, h6, p, mark, ol, ul, li
	background-color: transparent

*
	font-family: Helvetica

/* NEUTRALIZATION - END */

// SETUP - END


// LAYOUT - START

/* GENERAL - START */

div.DivForm
	background-color: $THEME_TERTIARY_SOFT_COLOR
	span, p
		color: $THEME_TERTIARY_DARK_COLOR
	span.SpanActiveStatus, i.IActiveStatus
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivCard
	+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivCardFinish
	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivForm
	div#divPercentageApproval, div#divPercentageConfirmation
		background-color: $THEME_SECONDARY_DARK_COLOR
		div#divSubPercentageProgress
			background-color: $THEME_SECONDARY_NORMAL_COLOR
		div#divSubPercentageApprovalProgress, div#divSubPercentageConfirmationProgress
			background-color: $THEME_SECONDARY_NORMAL_COLOR

div.DivProfileGeneral
	div.DivProfileInfo
		div.DivProfileInfoDetails
			a, span
				color: $THEME_TERTIARY_SOFT_COLOR

/* GENERAL - END */

/* HOME - START */

div#divBodyHome
	background-color: transparent
	+setScrollbarBackgroundColor($THEME_TERTIARY_SOFT_COLOR)
	+setScrollbarThumbColor($THEME_SECONDARY_DARK_COLOR)
	input#buttonNavigation
		background-image: url($URL_PHOTO_RESOURCE + "icon_navigation_show.svg")

/* HOME - END */

/* DASHBOARD - START */

div#divBodyHome
	input#buttonNavigation
		background-image: url($URL_PHOTO_RESOURCE + "icon_navigation_show.svg")

form div.DivForm fieldset, div.DivForm
	div.DivFormHorizontalContainer, div.DivFormVerticalContainer, div.DivFormListHorizontalContainer
		div.DivListChart
			ul
				background: rgba(0,0,0,.05)
				li
					color: $THEME_TERTIARY_DARK_COLOR
					span:first-of-type
						background-color: $THEME_SECONDARY_DARK_COLOR
						color: $THEME_TERTIARY_SOFT_COLOR

div#divMainDashboard
	div.DivDashboardEmeterai
		fieldset
			p
				background-color: $THEME_SECONDARY_DARK_COLOR
				color: $THEME_TERTIARY_SOFT_COLOR

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer
	ul.ListTableThumbnails, ul.ListTableTicket
		background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
	ul.ListTableConversation
		li
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
	ul.ListTableTicket
		li:nth-child(odd)
			background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
		span.TicketLow
			background-color: green
		span.TicketMedium
			background-color: orange
		span.TicketHigh
			background-color: red
	table
		thead
			tr
				th
					background-color: $THEME_PRIMARY_DARK_COLOR
		tbody
			tr:nth-child(even)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.1)
			tr:nth-child(odd)
				td
					background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)
			tr.TableRowCenter
				td
					text-align: center
				td.tdSpace
					height: 50px
					justify-content: center
				td.tdSpaceButton
					height: 80px
			tr.TableRowTotal
				background: $THEME_TERTIARY_SOFT_COLOR
				color: $THEME_PRIMARY_LIGHT_COLOR

form.FormInsertConversation, form.FormUploadFile, form.FormInsertConversationApprove
	table.TableListDetails
		color: $THEME_TERTIARY_DARK_COLOR


/* TABLE - END */

/* LOADING - START */

div#divCurtainLoading
	background-color: rgba(0, 0, 0, 0.6)
	div#divLoadingBar
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	span
		color: $THEME_TERTIARY_SOFT_COLOR

/* LOADING - END */

/* NOTIFICATION - START */

div#divNotificationInstall, div#divNotificationInstallList
	background-color: $THEME_PRIMARY_DARK_COLOR
	color: $THEME_TERTIARY_SOFT_COLOR
	input[type=button]
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

/* NOTIFICATION - END */

// LAYOUT - END


// LABEL - START

/* GENERAL - START */

form
	div.DivForm
		h3
			color: $THEME_SECONDARY_DARK_COLOR
		fieldset
			legend
				color: $THEME_SECONDARY_NORMAL_COLOR
			label
				color: $THEME_TERTIARY_DARK_COLOR
	label.LabelAttachment
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	label.LabelAttachmentDisable
		background: $THEME_CONVERSATION_DISABLED

div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
	header.HeaderGeneral
		h2, p
			color: $THEME_TERTIARY_SOFT_COLOR
	div.DivForm
		h3
			color: $THEME_SECONDARY_DARK_COLOR
		label, .legend-title
			color: $THEME_TERTIARY_DARK_COLOR
	div.DivCenterContainer
		div.DivForm
			div.DivSubHeaderHeadline
				label
					color: $THEME_SECONDARY_NORMAL_COLOR
				h2
					color: $THEME_SECONDARY_DARK_COLOR
			div.DivHeaderStatus
				span
					color: $THEME_TERTIARY_SOFT_COLOR
				span.SpanSelectStatus
					background-color: $THEME_SECONDARY_DARK_COLOR
				span.Urgent
					background-color: $THEME_INDICATOR_SEVERITY_URGENT_COLOR
				span.SeverityHigh
					background-color: $THEME_INDICATOR_SEVERITY_HIGH_COLOR
				span.SeverityMedium
					background-color: $THEME_INDICATOR_SEVERITY_MEDIUM_COLOR
				span.SeverityLow
					background-color: $THEME_INDICATOR_SEVERITY_LOW_COLOR
			div.DivFormHorizontalContainer
				div.DivHeaderInfo
					dl
						dt, dd
							color: $THEME_TERTIARY_DARK_COLOR
				div.DivHeaderList
					ul
						li
							background-color: $THEME_TERTIARY_LIGHT_COLOR
							span
								color: $THEME_TERTIARY_DARK_COLOR

div.DivForm, form div.DivForm fieldset
	textarea#spanIncidentNote
		background-color: white
	textarea.TextareaDisable
		background-color: $THEME_TERTIARY_SOFT_COLOR


/* GENERAL - END */

/* NAVIGATION - START */

nav
	color: $THEME_TERTIARY_SOFT_COLOR
	figure
		div, img#imageProfilePicture, span#spanProfileNameInitial
			border-color: $THEME_TERTIARY_SOFT_COLOR
		div
			background-color: transparent
			img#imageProfilePicture
				background-color: transparent
			span#spanProfileNameInitial
				background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
		a.SignOut
			color: $THEME_FORM_LABEL_ORANGE_COLOR


/* NAVIGATION - END */

/* SIGN IN - START */

div#divBodySignIn
	header#headerSignIn
		h1, span
			color: $THEME_TERTIARY_SOFT_COLOR
	div.DivEmailNotRegistered
		p.PEmailNotRegistered, a.AContactUs, p.PVersion
			color: $THEME_TERTIARY_SOFT_COLOR

/* SIGN IN - END */

/* DASHBOARD - START */

div#divMainDashboard, div#divBodyDashboard
	div.DivHighlight
		span.SpanHighlight
			background-color: $THEME_SECONDARY_DARK_COLOR
			color: $THEME_TERTIARY_SOFT_COLOR

/* DASHBOARD - END */

/* TABLE - START */

div.DivTableContainer, div.DivListContainer
	color: $THEME_TERTIARY_SOFT_COLOR
	span.SpanTicketStatus
		background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
	table.TableListDetailsAttachment
		label.LabelAttachment
			+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
		label.LabelAttachmentDisable
			background: $THEME_TERTIARY_NORMAL_COLOR
		label.LabelSpace
			margin-right: 5px
		label.LabelDisable
			background: linear-gradient(0deg, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_COLOR)
		figure.FigureAttachmentDisable
			filter: grayscale(80%)
/* TABLE - END */

/* DYNAMIC CONTAINER - START */

div.DivListControlButton
	label
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivTransparantContainer, div.DivListTransparantContainer
	background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)

div.DivTransparantContainerList
	background-color: rgba($THEME_TERTIARY_SOFT_RED, $THEME_TERTIARY_SOFT_GREEN, $THEME_TERTIARY_SOFT_BLUE, 0.2)

div.DivNavigationControlButton
	input[type=radio] + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	input[type=radio]:checked + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

div.DivNavigationControlButtonSPPA
	input[type=radio] + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)
	input[type=radio]:checked + label
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)


div.DivDynamicContainer
	form.FormInsertConversation, form.FormInsertConversationApprove
		div.DivConversationGeneral
			div.DivConversationContent
				div.DivAttachmentContainer
					background-color: $THEME_TERTIARY_LIGHT_COLOR
			div.DivConversationHeader
				div.DivConversationStatus
					span.SpanConversationDivision
						color: $THEME_TERTIARY_SOFT_COLOR

div.DivDynamicContainer
	// div.DivSubDynamicContainer
	div.DivFormListSurveyModel, div.DivFormListSurveyThirdPartyLiability
		label.LabelClaimLetterStatus, label.LabelClaimSurveyStatus
			a
				color: $THEME_TERTIARY_LIGHT_COLOR

.ProfileDivision
	background-color: $THEME_CONVERSATION_DIVISION_COLOR

.ProfileCustomer
	background-color: $THEME_CONVERSATION_CUSTOMER_COLOR

.ProfileBranch
	background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR

.PrivacyPrivate
	background-color: $THEME_CONVERSATION_PRIVATE_COLOR

.PrivacyPublic
	background-color: $THEME_CONVERSATION_PUBLIC_COLOR

.StatusReject
	background-color: $THEME_INDICATOR_FAIL_DARK_COLOR

.StatusApprove, .StatusApproveChecker, .StatusApproveSigner
	background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR

.StatusUpdate
	background-color: $THEME_INDICATOR_INFORMATION_COLOR

.StatusOther
	background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR

.StatusActive
	background-color: $THEME_PRIMARY_LIGHT_COLOR

.StatusNotActive
	background-color: $THEME_INDICATOR_SEVERITY_HIGH_COLOR

.Verified, .Verify
	background-color:  $THEME_INDICATOR_SUCCESS_DARK_COLOR

.NotVerified
	background-color: $THEME_INDICATOR_FAIL_DARK_COLOR

.Pending
	background-color: $THEME_SECONDARY_DARK_COLOR

.Resurvey
	background-color: $THEME_SECONDARY_DARK_COLOR

.Surveyed
	background-color: $THEME_PRIMARY_SOFT_COLOR

.Approved
	background-color: $THEME_PRIMARY_SOFT_COLOR

.ColorScoreVeryLow
	background-color: $THEME_SCORE_VERYLOW

.ColorScoreLow
	background-color: $THEME_SCORE_LOW

.ColorScoreMedium
	background-color: $THEME_SCORE_MEDIUM

.ColorScoreHigh
	background-color: $THEME_SCORE_HIGH

.ColorScoreVeryHigh
	background-color: $THEME_SCORE_VERYHIGH

.PendingDivision
	background-color: $THEME_PRIMARY_SOFT_COLOR

.Rejected
	background-color: $THEME_INDICATOR_FAIL_DARK_COLOR

.Approved
	background-color: $THEME_INDICATOR_SUCCESS_DARK_COLOR

.LetterWaitingForApprovalChecker
	background-color: $THEME_STATUS_WAITINGFORAPPROVALCHECKER

.LetterWaitingForApprovalSigner
	background-color: $THEME_STATUS_WAITINGFORAPPROVALSIGNER

.LetterRejected
	background-color: $THEME_STATUS_REJECTED

.LetterRevise
	background-color: $THEME_STATUS_REVISE

.LetterApproved
	background-color: $THEME_STATUS_APPROVED

/* DYNAMIC CONTAINER - END */

// LABEL - END


// INPUT -START

/* GENERAL - START */

input, select, textarea
	color: $THEME_TERTIARY_DARK_COLOR
	background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivRadioButtonContainer, div.DivRadioButtonContainerVertical
	div.DivRadioButton
		background-color: transparent
		border-color: $THEME_TERTIARY_DARK_COLOR
		label
			background-color: $THEME_SECONDARY_DARK_COLOR

div.DivCheckBoxContainer
	div.DivCheckBox
		background-color: transparent
		border-color: $THEME_TERTIARY_DARK_COLOR
		label
			background-color: $THEME_SECONDARY_DARK_COLOR

select
	background-image: url($URL_INPUT_RESOURCE + "input_dropdown.svg")

div.DivRoundButtonContainer
	label
		color: $THEME_SECONDARY_DARK_COLOR !important

/* GENERAL - END */

/* THIRD PARTY - START */

.mat-form-field-appearance-fill .mat-mdc-form-field-flex, .mdc-text-field--filled, .mdc-text-field:not(.mdc-text-field--disabled), .mdc-text-field__input, .mat-datepicker-toggle-active
	color: $THEME_TERTIARY_DARK_COLOR
	background-color: $THEME_TERTIARY_LIGHT_COLOR !important

.mat-mdc-form-field-focus-overlay
	background-color: transparent

.mdc-line-ripple
	border: none
	&::before
		border-bottom-style: none !important
	&::after
		border-bottom-style: none !important

.mat-mdc-icon-button, .mat-mdc-button-persistent-ripple
	&::before
		background-color: transparent !important

.mat-focused
	color: $THEME_TERTIARY_DARK_COLOR

.ng-select
	background-image: url($URL_INPUT_RESOURCE + "input_dropdown.svg")
	background-repeat: no-repeat
	background-position: right
	background-size: $SPACE_HUGE

.ng-dropdown-panel
	background-color: $THEME_TERTIARY_SOFT_COLOR


div.DivFormMultipleDropdown, div.DivFormSingleDropdown, div.DivFormLongTextDropdown
	.ng-select-container
		background-image: url($URL_INPUT_RESOURCE + "input_dropdown.svg")
		background-repeat: no-repeat
		background-position: right
		background-size: 36px
		background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-dropdown-panel
		background-color: $THEME_TERTIARY_LIGHT_COLOR

div.DivFormMultipleDropdown
	.ng-value
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-value-icon.left
			border: 1px solid $THEME_TERTIARY_DARK_COLOR

// DROPDOWN AND FILTER - START

div.DivFormMultipleDropdown, div.DivFormSingleDropdown
	.ng-select-container
		background-image: url($URL_INPUT_RESOURCE + "input_dropdown.svg")
		background-repeat: no-repeat
		background-position: right
		background-size: 36px
		background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-dropdown-panel
		background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-select-multiple.NgSelectMultipleSinggleDropdown, .ng-select.NgSelectSinggleDropdown
		.ng-select-container
			.ng-value-container
				.ng-placeholder
					color: $THEME_TERTIARY_NORMAL_COLOR
		.ng-dropdown-panel
			.ng-dropdown-panel-items
				.ng-option
					&:hover
						background-color: $THEME_TERTIARY_NORMAL_COLOR
						.ng-option-label
							color: $THEME_TERTIARY_SOFT_COLOR
				.ng-option-selected
					background-color: $THEME_SECONDARY_NORMAL_COLOR
					border: solid 0.5px $THEME_TERTIARY_LIGHT_COLOR
					.ng-option-label
						color: $THEME_TERTIARY_SOFT_COLOR
				.ng-option-disabled
					color: $THEME_TERTIARY_DARK_COLOR
					&:hover
						background-color: $THEME_TERTIARY_LIGHT_COLOR
	.ng-select-multiple.NgSelectMultipleSinggleDropdown
		.ng-select-container
			.ng-value-container
				.ng-value
					background-color: $THEME_TERTIARY_LIGHT_COLOR
					border-color: $THEME_TERTIARY_NORMAL_COLOR
					.ng-value-icon
						background-image: url($URL_ICON_RESOURCE + "icon_delete_round.svg")
						background-repeat: no-repeat
						background-size: contain
					.ng-value-label
						color: $THEME_TERTIARY_DARK_COLOR

div.DivFormMultipleDropdown
	.ng-value
		background-color: $THEME_TERTIARY_SOFT_COLOR
		.ng-value-icon.left
			border: 1px solid $THEME_TERTIARY_DARK_COLOR

// DROPDOWN AND FILTER - END


/* THIRD PARTY - END */

/* NAVIGATION - START */

ul.ListNavigation
	li.ListItemNavigation, li.ListItemSubNavigation
		a, label
			&:hover
				+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)


/* NAVIGATION - END */

/* SIGN IN - START */

/* SIGN IN - END */

// INPUT - END


// LINK - START

/* GENERAL - START */

a.ADownload
	color: $THEME_TERTIARY_SOFT_COLOR

form
	input[type=button]
		color: $THEME_TERTIARY_SOFT_COLOR
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	input[type=button].ButtonPositive
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_SUCCESS_NORMAL_COLOR, $THEME_INDICATOR_SUCCESS_NORMAL_RED, $THEME_INDICATOR_SUCCESS_NORMAL_GREEN, $THEME_INDICATOR_SUCCESS_NORMAL_BLUE, $THEME_INDICATOR_SUCCESS_DARK_COLOR, $THEME_INDICATOR_SUCCESS_DARK_RED, $THEME_INDICATOR_SUCCESS_DARK_GREEN, $THEME_INDICATOR_SUCCESS_DARK_BLUE)
	input[type=button].ButtonNegative
		+generateBackgroundGradientLeftRight($THEME_INDICATOR_FAIL_NORMAL_COLOR, $THEME_INDICATOR_FAIL_NORMAL_RED, $THEME_INDICATOR_FAIL_NORMAL_GREEN, $THEME_INDICATOR_FAIL_NORMAL_BLUE, $THEME_INDICATOR_FAIL_DARK_COLOR, $THEME_INDICATOR_FAIL_DARK_RED, $THEME_INDICATOR_FAIL_DARK_GREEN, $THEME_INDICATOR_FAIL_DARK_BLUE)
	input[type=button].ButtonPositive:disabled
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_INDICATOR_SUCCESS_NORMAL_RED, $THEME_INDICATOR_SUCCESS_NORMAL_GREEN, $THEME_INDICATOR_SUCCESS_NORMAL_BLUE, $THEME_INDICATOR_SUCCESS_DARK_COLOR, $THEME_INDICATOR_SUCCESS_DARK_RED, $THEME_INDICATOR_SUCCESS_DARK_GREEN, $THEME_INDICATOR_SUCCESS_DARK_BLUE)

input[type=button].ButtonAttachment
	margin: 0px !important
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_attachment.svg")

input[type=button].ButtonPDF
	background-image: url($URL_ICON_RESOURCE + "icon_file_pdf.svg")

input[type=button].ButtonExcel
	background-image: url($URL_ICON_RESOURCE + "icon_file_excel.svg")

input[type=button].ButtonCopy
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_copy.svg")

input[type=button].ButtonRemove
	background-image: url($URL_ICON_RESOURCE + "icon_remove.svg")

input[type=button].ButtonRemovefile
	background-image: url($URL_ICON_RESOURCE + "icon_remove.svg")

input[type=button].ButtonDownload
	background-image: url($URL_ICON_RESOURCE + "icon_download.svg")

input[type=button].ButtonSPPADownload
	background-image: url($URL_ICON_RESOURCE + "icon_download_sppa.svg")

input[type=button].ButtonAddTicket
	background-image: url($URL_ICON_RESOURCE + "icon_customer_care_white.svg")
	background-color: $THEME_SECONDARY_NORMAL_COLOR

input[type=button].ButtonAddPolicyRenewal
	background-image: url($URL_ICON_RESOURCE + "icon_search.svg")

input[type=button].ButtonAddPolicyOutstanding
	background-image: url($URL_ICON_RESOURCE + "icon_search.svg")

input[type=button].ButtonRetry
	background-image: url($URL_ICON_RESOURCE + "icon_retry.svg")
	background-color: $THEME_SECONDARY_NORMAL_COLOR

input[type=button]:disabled
	+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE)

input[type=button].ButtonSend
	background-image: url($URL_ICON_RESOURCE + "icon_send_round.svg")

input[type=button].ButtonDeleteRound
	background-image: url($URL_ICON_RESOURCE + "icon_delete_round.svg")

input[type=button].ButtonDownloadRound
	background-image: url($URL_ICON_RESOURCE + "icon_download_round.svg")

input[type=button].ButtonRemoveDocumentRequest
	margin: 0px !important
	background-color: transparent

input[type=button].ButtonEdit
	background-image: url($URL_ICON_RESOURCE + "icon_edit.svg")

input[type=button].ButtonAdd
	background-image: url($URL_ICON_RESOURCE + "icon_add.svg")

input[type=button].ButtonAddFull
	background-image: url($URL_ICON_RESOURCE + "icon_add_full.svg")

input[type=button].ButtonTableAdd
	background-image: url($URL_ICON_RESOURCE + "icon_table_add.svg")

input[type=button].ButtonUpdate
	background-image: url($URL_ICON_RESOURCE + "icon_update.svg")

input[type=button].ButtonDiscuss
	background-image: url($URL_ICON_RESOURCE + "icon_person_checklist.svg")

input[type=button].ButtonDiscussDisabled
	background-image: url($URL_ICON_RESOURCE + "icon_person_checklist_disabled.svg")

input[type=button].ButtonEditAset
	background-image: url($URL_ICON_RESOURCE + "icon_edit.svg")

input[type=button].ButtonEditFullDisabled
	background-image: url($URL_ICON_RESOURCE + "icon_edit_full_disabled.svg")

input[type=button].ButtonEditFullRed
	background-image: url($URL_ICON_RESOURCE + "icon_edit_full_red.svg")

input[type=button].ButtonEditFull
	background-image: url($URL_ICON_RESOURCE + "icon_edit_full.svg")


/* GENERAL - END */

/* HOME - START */

input[type=button]#buttonNavigation
	background-color: transparent

/* HOME - END */

/* PRODUCT INFORMATION - START */

input[type=button]#buttonCalculator
	background-color: transparent
	background-image: url($URL_ICON_RESOURCE + "icon_calculate.svg")

/* PRODUCT INFORMATION - END */

/* TABLE - START */

/* RENEWAL - START */

div.DivCardPolicy
	background-color: $THEME_TERTIARY_SOFT_COLOR
	span, h2
		color: $THEME_SECONDARY_NORMAL_COLOR

div.DivCardInformationPolicy
	+generateBackgroundGradientLeftRight($THEME_PRIMARY_LIGHT_COLOR, $THEME_PRIMARY_LIGHT_RED, $THEME_PRIMARY_LIGHT_GREEN, $THEME_PRIMARY_LIGHT_BLUE, $THEME_PRIMARY_DARK_COLOR, $THEME_PRIMARY_DARK_RED, $THEME_PRIMARY_DARK_GREEN, $THEME_PRIMARY_DARK_BLUE)

div.DivCardInformationPolicy
		span.spanStatusPolicyClosed
			background-color: $THEME_INDICATOR_SEVERITY_URGENT_COLOR
		span.spanStatusPolicyInforce
			background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
		span.spanStatusPolicyTemporary
			background-color: $THEME_PRIMARY_HIGHLIGHT_COLOR
		span.spanStatusPolicyWaiting
			+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
		span.spanStatusExecutive
			+generateBackgroundGradientLeftRight($THEME_POLICY_EXECUTIVE_COLOR, $THEME_POLICY_EXECUTIVE_RED, $THEME_POLICY_EXECUTIVE_GREEN, $THEME_POLICY_EXECUTIVE_BLUE, $THEME_INDICATOR_SEVERITY_LOW_COLOR, $THEME_INDICATOR_SEVERITY_LOW_RED, $THEME_INDICATOR_SEVERITY_LOW_GREEN, $THEME_INDICATOR_SEVERITY_LOW_BLUE)

/* RENEWAL - STOP */

/* TABLE - START */

div.DivTableControl, div.DivTableContainer, div.DivTableListControl
	div.DivButtonFirst.Enable, div.DivButtonPrevious.Enable, div.DivButton, div.DivButtonVideDetail
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	div.DivButtonNext.Enable, div.DivButtonLast.Enable
		+generateBackgroundGradientLeftRight($THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE, $THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE)
	div.DivButtonFirst.Disable, div.DivButtonPrevious.Disable, div.DivButtonFirst, div.DivButtonPrevious
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE, $THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE)
	div.DivButtonNext.Disable, div.DivButtonLast.Disable, div.DivButtonNext, div.DivButtonLast
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE, $THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE)
	div.DivButton.Disable
		+generateBackgroundGradientLeftRight($THEME_TERTIARY_NORMAL_COLOR, $THEME_TERTIARY_NORMAL_RED, $THEME_TERTIARY_NORMAL_GREEN, $THEME_TERTIARY_NORMAL_BLUE, $THEME_TERTIARY_DARK_COLOR, $THEME_TERTIARY_DARK_RED, $THEME_TERTIARY_DARK_GREEN, $THEME_TERTIARY_DARK_BLUE)

	div.DivButtonFirst input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_first.svg")
	div.DivButtonPrevious input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_previous.svg")
	div.DivButtonNext input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_next.svg")
	div.DivButtonLast input[type=button]
		background-image: url($URL_ICON_RESOURCE + "icon_last.svg")
	input[type=button]
		background-color: transparent
	span.SpanTablePage
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivSingleForm
	div.DivTableContainer
		ul.ListTableThumbnails
			color: $THEME_TERTIARY_DARK_COLOR

/* TABLE - END */

// LINK - END


// RCOVER START //

table.TableCheckList
	tbody
		tr
			td
				div.DivCheck
					border-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR

// RCOVER END //


// History Progress - START

div#divHistoryProgressStepper
	table.TableStepHistory
		tr.TableRowProgress
			td.TableDataProgressDetail
				p.ParagraphHistoryApprove
					color: $THEME_INDICATOR_SUCCESS_DARK_COLOR
				p.ParagraphHistoryRejected
					color: $THEME_INDICATOR_FAIL_DARK_COLOR
				p.ParagraphHistoryApproved
					color: $THEME_INDICATOR_SUCCESS_DARK_COLOR
				p.ParagraphHistoryVerification
					color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
				p.ParagraphHistory
					color: $THEME_SECONDARY_DARK_COLOR

// History Progress - END


// CONTAINER FILE ATTACHMENT DETAIL START

div.DivForm
	div.DivContainerAttachedment
		ul
			color: $THEME_TERTIARY_DARK_COLOR
			li
				div.DivButton
					input[type=button].ButtonDownload
						background-color: $THEME_SECONDARY_DARK_COLOR
					input[type=button].ButtonSwitch
						background-color: $THEME_TERTIARY_NORMAL_COLOR
						background-image: url($URL_ICON_RESOURCE + "icon_download.svg")

// CONTAINER FILE ATTACHMENT DETAIL END


// SPPA APPROVAL OTHER DOCUMENT - START

div.DivForm
	fieldset
		div.DivSPPAOtherDocumentContainer
			div.DivButtonSPAADocument
				div.DivButton
					+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)

// SPPA APPROVAL OTHER DOCUMENT - END


// SUBMISSION SALVAGE - STAR

table.TableSalvageAlignCenter
	tr.TableRowLast
		th
			background-color: white !important
			color: $THEME_PRIMARY_DARK_COLOR

div.DivHistoryApproval
	div.DivApprovalDetail
		label.LabelApprovalStatus
			color: white

label.LabelSalvageAttachment
	+generateBackgroundGradientLeftRight($THEME_SECONDARY_NORMAL_COLOR, $THEME_SECONDARY_NORMAL_RED, $THEME_SECONDARY_NORMAL_GREEN, $THEME_SECONDARY_NORMAL_BLUE, $THEME_SECONDARY_DARK_COLOR, $THEME_SECONDARY_DARK_RED, $THEME_SECONDARY_DARK_GREEN, $THEME_SECONDARY_DARK_BLUE)
	label.LabelSalvageAttachmentDisable
		background: $THEME_CONVERSATION_DISABLED

div.DivTableSalvageDetailContainer
	input[type=button]:disabled
		background-image: url($URL_ICON_RESOURCE + "icon_download.svg")


// SUBMISSION SALVAGE - END


// GCG ONLINE - START

div.DivMainGCGOnlineOverview
	div.DivSingleForm
		form
			div.DivForm
				div.DivCheckBoxContainer > label
					color: $THEME_SECONDARY_DARK_COLOR

div.DivMainGCGOnlineDetail
	div.DivSingleForm
		form
			div.DivForm
				label
					span.SpanStatusHistoryApproval
						color: $THEME_TERTIARY_SOFT_COLOR
					span#SpanStatusApproved
						background-color: $THEME_INDICATOR_SUCCESS_NORMAL_COLOR
					span#SpanStatusRejected
						background-color: $THEME_INDICATOR_FAIL_DARK_COLOR

div.DivMainGCGOnlineOverview div.DivSingleForm form div.DivForm fieldset,
div.DivMainGCGOnlineDetail div.DivSingleForm form div.DivForm fieldset.FieldsetContainerList
	ol
		li
			color: $THEME_TERTIARY_DARK_COLOR

// GCG ONLINE - END


// E-MATERAI - START

div.DivButtonDashboardEmaterai
	p
		color: $THEME_TERTIARY_SOFT_COLOR

div.DivTableContainer
	#divPdf
		#divBox
			background: url($URL_ICON_RESOURCE + "icon_materai.svg")
			max-width: 100%
			max-height: 100%

div.DivSingleForm
	div.DivFormVerticalContainer
		label.LabelEmateraiSurveyStatus
			a
				color: $THEME_TERTIARY_LIGHT_COLOR

h3#HeadDocumentPreview
	color: $THEME_TERTIARY_SOFT_COLOR

// E-MATERAI - END


// .FORM SURVEY SATISFACTION START
div.DivFormContainerForSatisfactionSurvey
	div.DivForm
		div.DivFormHorizontalContainer
			div.DivForm2Column
				div.DivContainerHorizontalRadioButton
					div.DivRadioButtonContainer
						label
							color: #797979
// .FORM SURVEY SATISFACTION END


// .TRACKING CLAIM START
div.DivTrackingStepperContainer
	ul
		li.LiStepperHorizontal, li.LiStepperHorizontalForChildNoLine, li.LiStepperHorizontalForParentNoLine
			div.DivContainerContentStepper
				span
					color: black
			div.DivTitleStepper
				ul
					li.LiStepperHorizontalForChild::after
						background-color: #349B70
					li.LiStepperHorizontalForChild::before, li.LiStepperHorizontalForChildNoLine::before
						background-color: #349B70
					li.LiStepperHorizontalForChildNoLineDisable::before
						background-color: #797979
				div.DivSubTitleStepper, div.DivSubTitleStepperForSurvey
					label::before
						background-color: #1C75BC
					label.LabelCheck::before
						background-color: transparent
						background-image: url($URL_ICON_RESOURCE + "icon_check_tracking.svg")

				div.DivContainerSteperContent
					label.labelTime::beforem
						background-color: #8d91f5
					label.labelTime::after
						background-color: #8d91f5
		li.LiStepperHorizontal::after
			background-color: #349B70
		li.LiStepperHorizontal::before, li.LiStepperHorizontalForParentNoLine::before
			background-color: #349B70
			box-shadow: 0px 0px 0px 4px white, 0px 0px 0px 8px  #349B70
		li.LiStepperHorizontalDisable::before, li.LiStepperHorizontalForParentNoLineDisable::before
			box-shadow: 0px 0px 0px 4px white, 0px 0px 0px 8px $THEME_TERTIARY_DARK_COLOR
			background-color: $THEME_TERTIARY_DARK_COLOR
		li.LiStepperHorizontalDisable::after, li.LiStepperHorizontalForParentNoLineDisable::after
			background-color: $THEME_TERTIARY_DARK_COLOR
		li.LiStepperHorizontalForChildNoLine::before
			background-color: #349B70


div.DivFormContainerForSatisfactionSurvey
	div.DivForm
		h2
			color: #F26F21

div.DivContainerSteperContent
	div.DivSubTitleStepperForSurvey
		div.DivContainerForRating
			div.DivWrapperForRating
				span
					color: #787878
				span::after
					background-image: url($URL_ICON_RESOURCE + "icon_star_fill.svg")

// .TRACKING CLAIM END

// KEY PERFORMANCE - START


div.DivTableContainer
	div.DivCheckBoxContainer
		div.DivCheckBox
			background-color: transparent
			border-color: $THEME_TERTIARY_DARK_COLOR
			label
				background-color: $THEME_SECONDARY_DARK_COLOR

div.DivContainerListHorizontal, div.DivMainGeneral
	div.DivTableContainer
		table
			td.TableColorGreen
				background-color: #5eb40e
			td.TableColorYellow
				background-color: #ddc426

div.DivFormButtonContainer
	div.DivCheckBoxContainer
		div.DivCheckBoxBrinsprime
			background-color: $THEME_TERTIARY_SOFT_COLOR
			border-color: $THEME_TERTIARY_SOFT_COLOR

div.DivFormButtonContainer
	div.DivCheckBoxContainer
		div.DivCheckBox
			background-color: $THEME_TERTIARY_SOFT_COLOR


// KEY PERFORMANCE - END

