/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// HYPERLINK - START

a.AClear
	color: inherit
	text-decoration: none
	border: none

a.AClear:link
	color: inherit
	text-decoration: none
	border: none

a.AClear:visited
	color: inherit
	text-decoration: none
	border: none

a.AClear:hover
	color: inherit
	text-decoration: none
	border: none

a.AClear:active
	color: inherit
	text-decoration: none
	border: none

a.AUnderline
	color: inherit
	text-decoration: underline

a.AUnderline:link
	color: inherit
	text-decoration: underline

a.AUnderline:visited
	color: inherit
	text-decoration: underline

a.AUnderlinek:hover
	color: inherit
	text-decoration: underline

a.AUnderline:active
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:link
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:visited
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownload:hover
	color: inherit
	text-decoration: underline
	font-weight: 600

a.ADownloade:active
	color: inherit
	text-decoration: underline
	font-weight: 600

nav
	figure
		a
			padding: $SPACE_TINY
			display: inline-block

a.ForgotPassword
	float: right
	margin-top: $SPACE_LITTLE
	margin-bottom: $SPACE_LITTLE
	// font-size: $fontsize_link_general
	display: block
	text-decoration: underline

a.SignUp
	text-decoration: underline

// HYPERLINK - END


// GENERAL - START

div#divNotificationInstall, div#divNotificationInstallList
	input[type=button]#buttonNotificationInstall, input[type=button]#buttonNotificationInstallList
		margin-left: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: 200px
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=button]#buttonNotificationInstallList
		margin: $SPACE_SMALL auto 0px auto
	input[type=button]#buttonNotificationClose
		background: none
		width: $HEIGHT_LINK_BUTTON
		height: $HEIGHT_LINK_BUTTON
		border: none
		background-repeat: no-repeat
		background-size: cover
		margin-right: $SPACE_LITTLE
		padding: $SPACE_TINY
		+setBorderRadius($RADUIS_LINK_BUTTON)
		flex-shrink: 0

form
	input[type=button]
		margin-top: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: 100%
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=button]:first-of-type
		margin-top: $SPACE_MEDIUM
	input[type=button]:last-of-type
		margin-bottom: $SPACE_MEDIUM
	input[type=button].ButtonRight
		margin-top: $SPACE_LITTLE
		width: 20%
		float: right
	input[type=button].ButtonRight
		margin-bottom: $SPACE_LITTLE
		width: 20%
		float: right
	div.DivFormButtonContainer
		display: flex
		justify-content: space-between
		input[type=button]
			margin: $SPACE_LITTLE
		input[type=button]:first-of-type
			margin-left: 0px
		input[type=button]:last-of-type
			margin-right: 0px
	div.DivEmailNotRegistered
		display: flex
		flex-direction: column
		p
			align-items: center
			margin-bottom: $SPACE_LITTLE
			margin-top: $SPACE_LITTLE
	div.FlexCenter
		justify-content: center

input[type=button].ButtonCopy, input[type=button].ButtonDelete, input[type=button].ButtonPDF, input[type=button].ButtonRemove, input[type=button].ButtonAttachment, input[type=button].ButtonDownload, input[type=button].ButtonAddTicket, input[type=button].ButtonPolicyRenewal, input[type=button].ButtonEdit, input[type=button].ButtonAdd, input[type=button].ButtonSPPADownload, input[type=button].ButtonTableAdd, input[type=button].ButtonUpdate
	width: $SIZE_ICON_LDPI
	height: $SIZE_ICON_LDPI
	vertical-align: middle
	display: inline-block
	background-size: contain
	background-position: center
	background-repeat: no-repeat
	border: none

input[type=button].ButtonAttachment
	margin: 0px
	+setBorderRadius(50%)
	background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI

input[type=button].ButtonRemove
	margin: 0px
	+setBorderRadius(50%)
	background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI

input[type=button].ButtonAddTicket
	width:$SIZE_ICON_MDPI
	height: $SIZE_ICON_MDPI
	+setBorderRadius(50%)
	background-size: $SIZE_ICON_MDPI $SIZE_ICON_MDPI

form
	input[type=button].inputAbout
		display: block
		margin-top: $SPACE_LITTLE
		margin-bottom: $SPACE_LITTLE
		height: $HEIGHT_LINK_BUTTON
		width: auto
		text-align: center
		font-size: $FONT_LINK_GENERAL
		+setBorderRadius($RADUIS_LINK_BUTTON)
		border: none
	input[type=file]
		display: none

form input[type=button].ButtonDelete
	margin: 0px
	+setBorderRadius($RADUIS_LINK_BUTTONTABLE)

// GENERAL - END


// HOME - START

input[type=button]#buttonNavigation
	position: absolute
	top: 40px
	left: 40px
	width: 30px
	height: 30px
	border: none

ul.ListNavigation
	li.ListItemNavigation, li.ListItemSubNavigation
		a, label
			border-radius: $RADIUS_BUTTON_GENERAL
			&:hover
				padding: $SPACE_SMALL

// HOME - END


// TABLE - START

div.DivTableControl, div.DivListControl, div.DivTableContainer, div.DivTableListControl
	div.DivButtonFirst, div.DivButtonPrevious, div.DivButtonNext, div.DivButtonLast, input[type=button], div.DivButton, div.DivButtonVideDetail
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		border: none
		background-repeat: no-repeat
		background-size: cover
		background-color: red
	div.DivButtonFirst, div.DivButtonPrevious, div.DivButtonNext, div.DivButtonLast, div.DivButton
		margin-left: $SPACE_TINY
		margin-right: $SPACE_TINY
		+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
		display: inline-block
	div.TableButtonContainer
		text-align: center
	input[type=button].ButtonDownload, input[type=button].ButtonRemove, input[type=button].ButtonEdit, input[type=button].ButtonAdd
		margin-top: 0px
		background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI
div.DivTableContainer
	ul.ListTableThumbnails
		div.DivButton
			margin-top: $SPACE_LITTLE
		input[type=button].ButtonDownload, input[type=button].ButtonRemove, input[type=button].ButtonEdit, input[type=button].ButtonAdd
			margin: 0px
			background-size: $SIZE_ICON_XLDPI $SIZE_ICON_XLDPI
	table.TableListDetails
		td
			div.DivButton
				margin-left: 0px
				height: $SPACE_HUGE
				width: $SPACE_HUGE
				display: flex
				align-content: center
				flex-direction: column
				align-items: center
				+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
				input[type=button].ButtonRemove, input[type=button].ButtonDownload, input[type=button].ButtonEdit, input[type=button].ButtonAdd
					margin: 0px
					background-size: $SIZE_LINK_BUTTONATTACHMENT $SIZE_LINK_BUTTONATTACHMENT
	table.TableListDetailsAttachment
		input[type=button].ButtonRemove, input[type=button].ButtonDownload, input[type=button].ButtonAttachment, input[type=button].ButtonEdit, input[type=button].ButtonAdd, input[type=button].ButtonUpdate, input[type=button].ButtonTableAdd
			margin: 0px -0.25px
			background-size: $SIZE_LINK_BUTTONATTACHMENT $SIZE_LINK_BUTTONATTACHMENT
div.DivTableContainer  input[type=button].ButtonRemove
	margin-top: 0px

ul.ListTableThumbnails
	li
		cursor: pointer
		cursor: hand

div.DivInnerDynamicContainer
	form
		div.DivTableContainer
			table
				tbody
					tr
						td.TableButtonContainer
							input[type=button]
								margin: 0px

// TABLE - END


// DYNAMIC CONTAINER - START

div.DivListControl
	div.DivListControlButton
		input[type=button]
			width: $SIZE_ICON_LDPI
			height: $SIZE_ICON_LDPI
			border: none
			background-repeat: no-repeat
			background-size: cover
			margin: $SPACE_TINY
			+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
			display: inline-block
		input[type=button]:first-of-type
			margin-left: $SPACE_TINY
		input[type=button]:last-of-type
			margin-right: $SPACE_TINY
	div.DivNavigationControlButton, div.DivNavigationControlButtonSPPA
		input[type=radio]
			display: none
		input[type=radio] + label
			width: 120px
			height: 30px
			border: none
			text-align: center
			padding-top: 10px
			background-repeat: no-repeat
			background-size: cover
			margin: $SPACE_LITTLE
			font-family: $URL_FAMILYPRIMARY_PRIMARY
			font-size: 1em
			+setBorderRadius($RADUIS_LINK_BUTTONTABLE)
			display: inline-block
		label
			margin-left: auto
			margin-right: auto
	div.DivNavigationControlButtonSPPA
		input[type=radio] + label
			width: 110px
			display: inline-flex
			height: 50px
			justify-content: center
			align-items: center
			padding: 0
		label.LabelInsured
			position: relative
			top: -10px


// DYNAMIC CONTAINER - END


// CONTAINER FILE ATTACHMENT DETAIL START

div.DivForm
	div.DivContainerAttachedment
		ul
			li
				div.DivButton
					input[type=button].ButtonSwitch
						width: $SIZE_ICON_LDPI
						height: $SIZE_ICON_LDPI
						vertical-align: middle
						display: inline-block
						background-size: contain
						background-position: center
						background-repeat: no-repeat
						border: none
						background-size:24px 24px
					input[type=button].ButtonDownload
						background-size:24px 24px


// CONTAINER FILE ATTACHMENT DETAIL END


// OTHER DOCUMENT SPPA APROOVAL START

div.DivForm
	fieldset
		div.DivSPPAOtherDocumentContainer
			div.DivButtonSPAADocument
				div.DivButton
					width: $SIZE_ICON_MDPI
					height: $SIZE_ICON_MDPI
					border: none
					background-repeat: no-repeat
					background-size: cover
					margin-left: $SPACE_TINY
					margin-right: $SPACE_TINY
					+setBorderRadius(50%)
					display: flex
					input[type=button].ButtonSPPADownload
						margin: 0px
						background-size: 26px 26px

// OTHER DOCUMENT SPPA APROOVAL END


// SALVAGE START

div#divBodyDashboard
	div.DivMainGeneral
		form
			div.DivButtonContainer
				input[type=button]
					width: 49%
					margin-top: $SPACE_LITTLE

label.LabelSalvageAttachment
	input[type=button]
		height: 18px
		margin: 15px 5px 5px 5px !important

div.DivTableSalvageDetailContainer
	input[type=button]:disabled
		width: $SIZE_ICON_LDPI
		height: $SIZE_ICON_LDPI
		vertical-align: middle
		display: inline-block
		background-size: contain
		background-position: center
		background-repeat: no-repeat
		border: none

// SALVAGE END


// GCG ONLINE - START

div#divBodyDashboard
	div#divMainDashboard
		div.DivSingleForm
			form
				div.DivButtonContainer
					input[type=button]
						width: 49%
						margin-top: $SPACE_LITTLE

// GCG ONLINE - END


// E-MATERAI - START

div.DivButtonDashboardEmaterai
	input[type=button]
		width: 300px

// E-MATERAI - END

// BRINSPRIME - START

// CHECKBOX BUTTON - START

form.FormBrinsprime
	div.DivFormButtonContainer
		div.DivCheckBoxContainerEnable
			background-color: orange
			padding: 4px 4px
			border-radius: 5px
		div.DivCheckBoxContainerDisable
			background-color: grey
			padding: 4px 4px
			border-radius: 5px
		div.DivCheckBoxContainer
			display: flex
			align-items: center
			div.DivCheckBox
				width: 15px
				height: 15px
				padding: $PADDING_INPUT_CHECKBOX
				+setBorderRadius(4px)
				display: flex
				align-items: center
				align-content: center
				justify-content: center
				input[type=checkbox]
					display: none
				figure
					img
						margin: $SPACE_ZERO
						width: $SPACE_ZERO
						height: $SPACE_ZERO
				input[type=checkbox]:checked + figure img
					width: 20px
					height: 20px
				input[type=checkbox]:disabled + figure img
					background-color: greyscale()
					cursor: not-allowed
			div.DivCheckBox + figure img
				margin-right: $SPACE_MEDIUM
				margin-top: $SPACE_NARROW
				margin-bottom: $SPACE_NARROW

// CHECKBOX BUTTON - END

// BRINSPRIME - END