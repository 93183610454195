/* @charset "utf-8"; */
/* CSS Document */

// IMPORT - START

@import form-initialization-general

// IMPORT - END


// SCREEN WIDTH 1450px - START

@media screen and (min-width: 1450px)
	div.DivTableContainer
		ul.ListTableConversation
			li
				div.DivConversationGeneral
					div.DivConversationHeader
						div.DivConversationSubHeader
							span.SpanConversationTitle
								max-width: initial !important

// SCREEN WIDTH 1450px - END


// SCREEN WIDTH 1424px - START

@media screen and (max-width: 1424px)
	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: none

// SCREEN WIDTH 1424px - END


// SCREEN WIDTH 1330px - START

@media screen and (max-width: 1330px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			flex-direction: column
			div.DivContainer
				margin-top: $SPACE_SMALL
				margin-right: 0px
				margin-left: 0px
				margin-bottom: $SPACE_SMALL
			div.DivFormVerticalContainer
				flex-direction: row
				justify-content: flex-start
	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			div.DivAttachmentContainer
				display: flex
				justify-content: flex-end

// SCREEN WIDTH 1330px - END


// SCREEN WIDTH 1250px - START

@media screen and (max-width: 1250px)
	div#divBodyHome main
		padding: $SPACE_LARGE

	div.DivVerifierGeneral, div.DivCenterGeneral
		margin: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_LARGE

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_LARGE

	input[type=button]#buttonNavigation
		left: $SPACE_LARGE

	div.DivBodyGeneral, div.DivVerifierGeneral, div.DivCenterGeneral
		margin-top: 60px

	div.DivMainAsideSearch
		form
			margin-right: $SPACE_LITTLE
			width: $WIDTH_LAYOUT_SIGNIN_COMPACT

	div.DivContainerListHorizontal
		div.DivListContainer
			flex-direction: column
			div.DivTableListContainer
				margin-bottom: $SPACE_HUGE

	div.DivTableContainer
		margin-left: $SPACE_LITTLE

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 140px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 140px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

// SCREEN WIDTH 1250px - END


// SCREEN WIDTH 1140px - START

@media screen and (max-width: 1140px)
	div#divBodyHome main
		padding: $SPACE_SMALL

	div.DivVerifierGeneral, div.CenterGeneral
		margin: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader, input[type=button]#buttonNavigation
		top: $SPACE_SMALL

	div#divBodyHome img#imageLogoHeader, img#imageLogoHeader
		right: $SPACE_SMALL

	input[type=button]#buttonNavigation
		left: $SPACE_SMALL

	div.DivMainAsideSearch
		flex-direction: column
		form
			width: 100%
		div.DivDynamicContainer
			margin-left: 0px
			div.DivSubDynamicContainer
				margin-top: 0px

	div.DivContainerListHorizontal
		div.DivListContainer
			form
				div.DivForm
					fieldset
						div.DivFormListHorizontalContainer
							padding-left: $SPACE_LITTLE
							flex-direction: column
							div.DivForm2Column
								width: 100%
							div.DivRoundButtonContainer
								display: none
				input[type=button]#inputButtonSubmit
					display: block

	div.DivRoundButtonContainer
		display: none

	div.DivForm
		padding-left: $SPACE_SMALL
		padding-right: $SPACE_SMALL

	div.DivTableContainer
		margin-left: 0px

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 100px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 100px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationHeader
					div.DivConversationProfile
						display: block

	div.DivMainAsideSearch
		div.DivMainTable
			margin-left: $SPACE_ZERO

// SCREEN WIDTH 1140px - END


// SCREEN WIDTH 950px - START

@media screen and (max-width: 950px)
	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivFormVerticalContainer, div.DivFormHorizontalContainer
				flex-direction: column
				align-content: center
				align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormVerticalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px

	div.DivTableContainer
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				div.DivConversationStatus
					flex-direction: column
					flex-shrink: 1
				div.DivConversationHeader
					flex-direction: column
					align-items: flex-end
					div.DivConversationProfile
						display: none
			table.TableListDetails
				tbody
					tr
						td
							display: table-cell
						td:nth-child(2), td:nth-child(3)
							width: auto
						td:nth-child(3)
							display: none


// SCREEN WIDTH 950px - END


// SCREEN WIDTH 800px - START

@media screen and (max-width: 800px)
	div.DivVerifierGeneral, div.DivCenterGeneral
		header.HeaderGeneral
			padding-top: 60px

	div.DivVerifierGeneral, div.DivCenterGeneral
		img#imageLogoHeader
			right: auto
			left: 0px

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			div.DivAttachmentContainer
				display: flex
				justify-content: flex-end
		div.DivDashboardEmeterai
			flex-direction: column
			text-align: center

	div.DivTableContainer
		table
			thead
				tr
					th
						word-break: break-all
			tbody, tfoot
				tr
					td
						word-break: break-all
		ul.ListTableThumbnails
			justify-content: space-around
		ul.ListTableConversation
			div.DivConversationGeneral
				div.DivConversationItem
					div.DivConversationHeader
						justify-content: flex-end

	+generateKeyFrames(animationNavigationShow)
		0%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)
		100%
			margin-left: 80px
			+setOpacity(1.0)

	+generateKeyFrames(animationNavigationHide)
		0%
			margin-left: 80px
			+setOpacity(1.0)
		100%
			margin-left: $SPACE_NAVIGATION_HIDE
			+setOpacity(0.0)

	div#divBodyDashboard
		div.DivMainGeneral
			form
				div.DivButtonContainer
					width: 100%
		div#divMainDashboard
			div.DivSingleForm
					form
						div.DivButtonContainer
							input[type=button]
								width: 100%

// SCREEN WIDTH 800px - END


// SCREEN WIDTH 640px - START

@media screen and (min-width: 641px)
	div#divNotificationInstall
		width: 640px
		height: auto
		left: auto
		position: fixed
		right: $SPACE_LITTLE
		bottom: $SPACE_LITTLE
		+setBorderRadius($RADIUS_INPUT_GENERAL)

@media screen and (max-width: 640px)
	body
		font-size: 95%

	div#divBodyHome img#imageLogoHeader.DisplayNone
		display: none

	div#divBodyDashboard, div#divBodyRelationshipManagerDetail
		div.DivContainerHorizontal, div.DivContainerVertical
			div.DivContainer
				div.DivFormHorizontalContainer
					flex-direction: column
					align-content: center
					align-items: center

	form div.DivForm fieldset, div.DivForm
		div.DivFormHorizontalContainer
			flex-direction: column
			div.DivForm2Column
				margin-left: 0px
				margin-right: 0px
				width: 100%
			div.DivForm2ColumnNoMargin:last-of-type:nth-of-type(odd)
				div.DivImageCapture
					width: 100%

	div.DivForm, form div.DivForm fieldset
		.mat-form-field
			width: 100%

	div.DivDynamicContainer
		div.DivConversationHeader
			div.DivConversationProfile
				display: none

	div.DivTableContainer, div.DivTableSalvageDetailContainer
		ul.ListTableTicket
			img
				display: none
			div.DivTicketItem
				div.DivTicketSubItem:last-of-type
					display: flex
					flex-direction: column
				div.DivTicketTail
					display: flex
					flex-shrink: 1
			li
				padding-left: $SPACE_SMALL
				padding-right: $SPACE_SMALL
		ul.ListTableConversation
			li.LiConversationContainerRight, li.LiConversationContainerLeft
				justify-content: center
				width: 100%
		div.DivTableControl, div.DivTableControlLeft
			flex-direction: column
			div.DivTableSubControl
				margin-top: $SPACE_TINY
				margin-bottom: $SPACE_TINY
		table
			margin-top: $SPACE_SMALL
			margin-bottom: $SPACE_SMALL
			thead
				tr
					th
						padding: $SPACE_THIN
						display: block
					th:first-of-type
						padding-left: 0px
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
						+setBorderRadiusBottom(0px)
					th:last-of-type
						padding-right: 0px
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				tr:first-of-type
					th
						padding-top: $SPACE_TINY
				tr:last-of-type
					th
						padding-bottom: $SPACE_TINY
			tbody, tfoot
				tr
					td
						padding: $SPACE_THIN
						display: block
						+setBorderRadius(0px)
					td:first-of-type
						padding-top: $SPACE_TINY
						padding-left: 0px
					td:last-of-type
						padding-right: 0px
						padding-bottom: $SPACE_TINY
				tr:first-of-type
					td:first-of-type
						+setBorderRadiusTop($RADIUS_TABLE_GENERAL)
				tr:last-of-type
					td:first-of-type
						+setBorderRadiusTop(0px)
						+setBorderRadiusBottom(0px)
					td:last-of-type
						+setBorderRadiusBottom($RADIUS_TABLE_GENERAL)
				div.DivMultipleLabelAttachment
					margin: 0
			th, td
				text-align: center

	main.MainShow
		+setSlideTransform(0, 0px, 0px, 1.0, 1.0, 1.0)
		+setTransition(all, 0.5, ease-in)
	main.MainHide
		+setSlideTransform(-60, 180px, -180px, 0.6, 0.6, 0.6)
		+setTransition(all, 0.5, ease-out)

	div.DivListControl
		div.DivListControlButton
			div.DivNavigationControlButtonSPPA
				input[type=radio] + label
					width: 105px


	// START - HISTORY PROGRESS

	div#divHistoryProgressStepper
		table.TableStepHistory
			tr.TableRowProgress
				flex-direction: column
				border-left: solid 1px #000
				p
					font-size: 0.9em
				td.TableDataProgressDate, td.TableDataProgressDetail
					margin-left: 10px
				td.TableDataProgressDate
					border-right: 0
				td.TableDataProgressDetail
					p
						margin-top: 5px


	// END - HISTORY PROGRESS


	// SPPA APPROVAL OTHER DOCUMENT START

		div.DivForm
		fieldset
			div.DivSPPAOtherDocumentContainer
				flex-direction: column-reverse
				width: 90%
				margin: auto
				margin-bottom: 20px
				flex-wrap: wrap
				align-items: center
				div.DivIconSPPADocument
					display: none
				div.DivButtonSPAADocument
					margin: 10px

	// SPPA APPROVAL OTHER DOCUMENT END

	div.DivSubDynamicContainer
		form
			div.DivForm
				div.DivFormHorizontalContainer
					div.DivForm3ColumnButton
						width: 100%

	// SUMMARY DECLARATION START

	div.DivSummaryDeclaration
		div.DivContentSummaryDeclaration
			display: flex
			flex-direction: column !important

	// SUMMARY DECLARATION END


	// RETURN AMOUNT START //

	div.DivForm
		div.DivFormReturnAmount
			fieldset
				h3:first-of-type
					margin-top: (-$SPACE_SMALL)
					margin-left: (-$SPACE_SMALL)
				h3
					margin-top: (-$SPACE_SMALL)
					font-size: 12pt
					align-items: center

	// RETURN AMOUNT END //


	// SALVAGE START //

	div.DivTableSalvageDetailContainer
		table
			tbody
				tr
					td.TableDataAttachment
						padding-left: 0px
		table.TableSalvageAlignCenter
			text-align: center
			th
				width: 100% !important
		table.TableListDetailsAttachment
			td
				width: 100% !important
				word-break: break-all

	// SALVAGE END //


	// E-MATERAI - START

	div.DivBodyEmaterai
		flex-direction: column-reverse

	div.DivButtonDashboardEmaterai
		flex-direction: column-reverse
		align-items: center
		text-align: center

	// E-MATERAI - END

// SCREEN WIDTH 640px - END


// SCREEN WIDTH 490px - START

@media screen and (max-width: 490px)
	ul.ListTableConversation
		li.LiConversationContainerRight, li.LiConversationContainerLeft
			div.DivConversationGeneral
				div.DivConversationItem
					flex-direction: column
					align-items: flex-start
					div.DivConversationHeader
						margin-top: $SPACE_LITTLE
						div.DivConversationSubHeader
							span
								text-align: left !important
							span.SpanConversationTitle
								max-width: initial !important

// SCREEN WIDTH 490px - END
